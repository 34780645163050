import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import dashboardReducer from "../pages/Dashboard/dashboardSlice";
import usersReducer from "../pages/Login/usersSlice";
import transactionsReducer from "../pages/Transactions/transactionsSlice";
import settlementsReducer from "../pages/Balances/settlementsSlice";
import analyticReducer from "../pages/Analytics/analyticsSlice";
import rateReducer from "../pages/ExchangeRate/rateSlice";
import wireReducer from "../pages/KlashaWire/wiresSlice";
import complianceReducer from "../pages/Compliance/complianceSlice";
import settingsReducer from "../pages/Settings/settingsSlice";
import paymentLinksReducer from "../pages/PaymentLinks/linksSlice";
import walletsReducer from "../pages/Wallets/redux/slice";
import cardsReducer from '../pages/Cards/CardSlice';
import { refundReducer } from "../pages/Refunds/redux";
import { multiUserReducer } from "../pages/Settings/Team/redux";
import formDataReducer from "../pages/Wallets/redux/formDataSlice";
import { whiteListReducer } from "../pages/Settings/IPWhiteList/redux";

const appReducer = combineReducers({
  dashboard: dashboardReducer,
  users: usersReducer,
  transactions: transactionsReducer,
  links: paymentLinksReducer,
  formData: formDataReducer,
  settlements: settlementsReducer,
  analytic: analyticReducer,
  rates: rateReducer,
  wires: wireReducer,
  compliance: complianceReducer,
  settings: settingsReducer,
  wallets: walletsReducer,
  cards: cardsReducer,
  refunds: refundReducer,
  multiUser: multiUserReducer ,
  ipAddressWhiteList: whiteListReducer
});

const rootReducer = (state, action) => {
  if (action.type === "persist/PURGE") {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
