import React, { useEffect } from "react";
import {
    BottomDiv,
    PayButton,
    Wrapper,
    BottomDivHeading,
    BottomLogoDiv,
    WelcomeTitle,
    MiddleDiv,
    LeftMiddleDiv,
    RightMiddleDiv,
    MiddleDivLink,
    MiddleDivText,
    MiddleDivTitle,
    BottomTitleParagraph,
    BackLinesLeft1,
    BackLinesRight1,
    BackLinesLeft2,
    BackLinesRight2,
    BottomBackLinesMobile,
    MobileTopDiv,
    MobileBottomDiv,
} from "./style";
import { ReactComponent as Primer } from "../../../assets/images/primer.svg";
import { ReactComponent as EcWid } from "../../../assets/images/ecwid.svg";
import { ReactComponent as BigCommerce } from "../../../assets/images/bigcommerce.svg";
import { ReactComponent as SquareSpace } from "../../../assets/images/squarespace.svg";
import { stringToSentenceCase } from "../../../utils/format";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../app/hooks";
import BusinessStatus from "./components/BusinessStatus";
import { ReactComponent as PendingStatusIcon } from "./assets/info-reviewed.svg";
import { ReactComponent as ActiveStatusIcon } from "./assets/info-active.svg";
import { ReactComponent as DisapprovedStatusIcon } from "./assets/disapproved-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { getUploadedDocumentsByBusinessId } from "../../../pages/Compliance/complianceSlice";
import { Button } from "../../common/Button";

const Dashboard = () => {
    const navigate = useNavigate();
    const { userObj, businessId } = useAppSelector(
        (state) => state.users,
    );
    const dispatch = useAppDispatch();
    const { uploadedDocuments } = useAppSelector(
        (state) => state.compliance,
    );

    useEffect(() => {
        if (businessId) {
            dispatch(
                getUploadedDocumentsByBusinessId({
                    businessId,
                }),
            );
        }
    }, [businessId]);

    const disapprovedDocuments = uploadedDocuments
        ? uploadedDocuments?.find(
              (document) =>
                  document?.documentApprovalStatus ===
                  "FAILED",
          )
        : [];

    const approvedDocuments = uploadedDocuments
        ? uploadedDocuments.every(
              (document) =>
                  document?.documentApprovalStatus ===
                  "APPROVED",
          )
        : [];

    const everyPendingDocuments = uploadedDocuments
        ? uploadedDocuments.find(
              (document) =>
                  document?.documentApprovalStatus ===
                  "PENDING",
          )
        : [];


    return (
        <Wrapper>
            <WelcomeTitle>
                Welcome{" "}
                {userObj?.firstName
                    ? stringToSentenceCase(
                          userObj?.firstName,
                      )
                    : "User"}
                !
            </WelcomeTitle>

            {
                (!disapprovedDocuments && everyPendingDocuments ) &&
                uploadedDocuments?.length > 0 && (
                    <BusinessStatus
                        status_title="Your account is being reviewed"
                        status_message="The team is currently reviewing your KYB documents. 
                        You will get an update within 24-48 hours."
                        status_icon={<PendingStatusIcon />}
                        business_status="pending"
                    />
                )}

            {approvedDocuments &&
                uploadedDocuments?.length > 0 && (
                    <BusinessStatus
                        status_title="Your account is now active"
                        status_icon={<ActiveStatusIcon />}
                        business_status="active"
                    />
                )}

            {
                 disapprovedDocuments &&
                uploadedDocuments?.length > 0 && (
                    <BusinessStatus
                        status_title="Your documents did not meet our KYB requirements"
                        status_message={
                            <>
                                Thank you for uploading your
                                documents. However, your
                                document has not been
                                approved because it does not
                                meet the necessary
                                requirements. Could you
                                please upload a new one and
                                resubmit it{" "}
                                <Link to="/document/re-upload">
                                    here
                                </Link>
                                .
                            </>
                        }
                        status_icon={
                            <DisapprovedStatusIcon />
                        }
                        business_status="disapproved"
                    />
                )}

            <MiddleDiv>
                <LeftMiddleDiv>
                    <BackLinesLeft1 />
                    <BackLinesRight1 />
                    <MiddleDivTitle>
                        Send large payments from Africa
                        overseas
                    </MiddleDivTitle>
                    <MiddleDivText>
                        Wire allows you to make large
                        international transactions in your
                        local currency to over 120
                        currencies.
                    </MiddleDivText>
                    <MiddleDivLink to="/wire">
                        Send a wire
                    </MiddleDivLink>
                </LeftMiddleDiv>
                <RightMiddleDiv>
                    <BackLinesLeft2 />
                    <BackLinesRight2 />
                    <MiddleDivTitle>
                        Receive payments from Africa without
                        a website
                    </MiddleDivTitle>
                    <MiddleDivText>
                        Create and share your customised
                        Klasha payment link to receive
                        payments in NGN, GHS, TZS and more.
                    </MiddleDivText>
                    <MiddleDivLink to="/link">
                        Create your links
                    </MiddleDivLink>
                </RightMiddleDiv>
            </MiddleDiv>

            <MobileTopDiv>
                <MiddleDivTitle>
                    Hey 👋🏾 <br /> You haven’t made any
                    actions
                </MiddleDivTitle>
                <p>
                    Add your bank account to start making
                    fast payments
                </p>

                <Button
                    label="Add an account"
                    height="48px"
                    width="155px"
                    fontSize="14px"
                    onClick={() =>
                        navigate("/settings/accounts")
                    }
                />
            </MobileTopDiv>

            <BottomDiv>
                <BottomDivHeading>
                    Increase your conversions and reduce
                    cart abandonment
                </BottomDivHeading>
                <BottomTitleParagraph>
                    We integrate seamlessly into a host of
                    e-commerce platforms through our API,
                    allowing you to easily accept payments
                    online in African currencies.
                </BottomTitleParagraph>
                <BottomLogoDiv>
                    <Primer />
                    <EcWid />
                    <BigCommerce />
                    <SquareSpace />
                </BottomLogoDiv>
                <PayButton href="https://klasha.com/business/integrations">
                    Integrate Klasha pay
                </PayButton>
            </BottomDiv>

            <MobileBottomDiv>
                <BottomBackLinesMobile />
                <MiddleDivTitle>
                    Klasha wire - send money to businesses
                    globally from Africa
                </MiddleDivTitle>
                <p>
                    Wire allows you to make large
                    international transactions in your local
                    currency to over 120 countries.
                </p>
                <Button
                    label="Send a Wire"
                    height="48px"
                    width="115px"
                    fontSize="14px"
                    onClick={() => navigate("/wire")}
                />
            </MobileBottomDiv>
        </Wrapper>
    );
};

export default Dashboard;
