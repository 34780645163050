const pathResolver = (modulePath: string) => modulePath;

export const appRoutes = {
    root: () => "/",
    login: () => pathResolver("/auth/login"),
    forgotPassword: () => pathResolver("/auth/forgot-password"),
    resetPassword: () => pathResolver("/auth/reset-password"),
    createAccount: () => pathResolver("/auth/signup"),
    registrationConfirmed: () => pathResolver("/registrationConfirm/:id"),
    dashboard: () => "/dashboard",
    collections: () => "/collection",
    collectionTransactions: () => pathResolver("/collection/:source_currency"),
    customers: () => pathResolver("/customers"),
    customerDetail: () => pathResolver("/customers/:accountNumber"),
    wallets: () => pathResolver("/wallets"),
    walletDetail: () => pathResolver("/wallets/:currencyCode"),
    cards: () => pathResolver("/cards"),
    withdrawFundsFromCard: () => pathResolver("cards/withdraw-funds/"),
    fundCard: () => pathResolver("cards/send-funds"),
    transactions: () => pathResolver("/transactions"),
    refunds: () => pathResolver("/refunds"),
    rates: () => pathResolver("/rates"),
    installation: () => "/checkout",
    paymentLinks: () => pathResolver("/payment-links"),
    virtualAccount: () => pathResolver("/virtual-account"),
    settlements: () => pathResolver("/settlements"),
    settlementPayout: () => pathResolver("/settlements/fund/payout"),
    fundUSDSettlement: () => pathResolver("/settlements/fund/settlement"),
    klashaWire: () => pathResolver("/klasha-wire"),
    contactUs: () => pathResolver("/contact"),
    bassFunding: () => pathResolver("/wallets/:currencyCode/fund-account"),
    bassWithdrawal: () => pathResolver("/wallets/withdrawal/bass"),
    bassAccount: () => pathResolver("/wallets/:currencyCode/account-creation/*"),
    createTeamRole: () => pathResolver(`/settings/team/create-role`),
    setTeamPassword: () => pathResolver("/activate-team-invite/:token"),
    viewTeamPermission: () => pathResolver("/settings/team/:name"),
    settings: () => pathResolver("settings/"),
    settingsTab: () => pathResolver("settings/:tab"),
    compliance: () => pathResolver("/compliance"),
    manageBeneficiary: () => pathResolver("/manage-beneficiaries"),
    reUploadDocuments: () => pathResolver("/upload-documents"),
    Marketing: () => pathResolver("/marketing"),
    UnsubScribe: () => pathResolver("/un-subscribe/:email/:id"),
    sendWire: () => pathResolver("/klasha-wire/send"),
    successOrange: () => pathResolver("/success-orange"),
    failedOrange: () => pathResolver("/failed-orange"),
    updatePassword: () => pathResolver("/update-password"),
    swap: () => pathResolver("/wallets/swap/currency/"),
}

export const appRoutePaths: {
    root: string;
    dashboard: string;
    login: string;
    forgotPassword: string;
    resetPassword: string;
    createAccount: string;
    registrationConfirmed: string;
    collections: string;
    collectionTransactions:string;
    customers: string;
    customerDetail:string;
    wallets: string;
    walletDetail: string;
    cards: string;
    withdrawFundsFromCard: string;
    fundCard: string;
    transactions: string;
    refunds: string;
    rates: string;
    installation: string;
    paymentLinks: string;
    virtualAccount: string;
    settlements: string;
    settlementPayout: string;
    fundUSDSettlement: string;
    klashaWire: string;
    contactUs: string;
    bassFunding: string;
    bassWithdrawal: string;
    bassAccount: string;
    createTeamRole: string;
    setTeamPassword: string;
    viewTeamPermision: string;
    settings: string;
    settingsTab: string;
    compliance: string;
    manageBeneficiary: string;
    reUploadDocuments: string;
    marketing: string;
    unsubScribe: string;
    sendWire: string;
    successOrange: string;
    failedOrange: string;
    updatePassword: string;
    swap: string;
} = {
    root: appRoutes.root(),
    login: appRoutes.login(),
    forgotPassword: appRoutes.forgotPassword(),
    resetPassword: appRoutes.resetPassword(),
    createAccount: appRoutes.createAccount(),
    registrationConfirmed: appRoutes.registrationConfirmed(),
    dashboard: appRoutes.dashboard(),
    collections: appRoutes.collections(),
    collectionTransactions: appRoutes.collectionTransactions(),
    customers: appRoutes.customers(),
    customerDetail: appRoutes.customerDetail(),
    wallets: appRoutes.wallets(),
    walletDetail: appRoutes.walletDetail(),
    cards: appRoutes.cards(),
    withdrawFundsFromCard: appRoutes.withdrawFundsFromCard(),
    fundCard: appRoutes.fundCard(),
    transactions: appRoutes.transactions(),
    refunds: appRoutes.refunds(),
    rates: appRoutes.rates(),
    installation: appRoutes.installation(),
    paymentLinks: appRoutes.paymentLinks(),
    virtualAccount: appRoutes.virtualAccount(),
    settlements: appRoutes.settlements(),
    settlementPayout: appRoutes.settlementPayout(),
    fundUSDSettlement: appRoutes.fundUSDSettlement(),
    klashaWire: appRoutes.klashaWire(),
    contactUs: appRoutes.contactUs(),
    bassFunding: appRoutes.bassFunding(),
    bassAccount: appRoutes.bassAccount(),
    bassWithdrawal: appRoutes.bassWithdrawal(),
    createTeamRole: appRoutes.createTeamRole(),
    setTeamPassword: appRoutes.setTeamPassword(),
    viewTeamPermision: appRoutes.viewTeamPermission(),
    settings: appRoutes.settings(),
    settingsTab: appRoutes.settingsTab(),
    compliance: appRoutes.compliance(),
    manageBeneficiary: appRoutes.manageBeneficiary(),
    reUploadDocuments: appRoutes.reUploadDocuments(),
    marketing: appRoutes.Marketing(),
    unsubScribe: appRoutes.UnsubScribe(),
    sendWire: appRoutes.sendWire(),
    successOrange: appRoutes.successOrange(),
    failedOrange: appRoutes.failedOrange(),
    updatePassword: appRoutes.updatePassword(),
    swap: appRoutes.swap(),

}
