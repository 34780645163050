import React from "react";
import { ReactComponent as DashboardIcon } from "./icons/dashboard.svg";
import { ReactComponent as TransactionsIcon } from "./icons/transactions.svg";
import { ReactComponent as BalancesIcon } from "./icons/balance.svg";
import { ReactComponent as WalletIcon } from "./icons/wallet.svg";
import { ReactComponent as ExchangeIcon } from "./icons/exchange.svg";
import { ReactComponent as CheckoutIcon } from "./icons/checkout.svg";
import { ReactComponent as LinkIcon } from "./icons/payment-link.svg";
import { ReactComponent as WireIcon } from "./icons/wire.svg";
import { ReactComponent as CustomerIcon } from "./icons/customer-icon.svg";
import { ReactComponent as VirtualAccountIcon } from "./icons/virtual-account.svg";
import { ReactComponent as SettlementsIcon } from "./icons/settlements.svg";
import { ReactComponent as RefundIcon } from "./icons/refundIcon.svg";
import { ReactComponent as SettingsIcon } from './icons/settlements.svg';
import { ReactComponent as LogoutIcon } from './icons/logout.svg';

const mainLinks = [
  {
    key: "dashboard",
    route: "/dashboard",
    name: "Dashboard",
    icon: <DashboardIcon />,
  },
  {
    key: "collections",
    route: "/collection",
    name: "Collections",
    icon: <BalancesIcon />,
  },
  {
    key: "customers",
    route: "/customers",
    name: "Customers",
    icon: <CustomerIcon />,
  },
  {
    key: "wallet",
    route: "/wallets",
    name: "Wallets",
    icon: <WalletIcon />,
  },
  {
    key: "transactions",
    route: "/transactions",
    name: "Transactions",
    icon: <TransactionsIcon />,
  },

  {
    key: "refunds",
    route: "/refunds",
    name: "Refunds",
    icon: <RefundIcon />,
  },
  {
    key: "exchange-rates",
    route: "/rates",
    name: "Exchange rates",
    icon: <ExchangeIcon />,
  },
];

const acceptLinks = [
  {
    key: "checkout",
    route: "/checkout",
    name: "Klasha pay",
    icon: <CheckoutIcon />,
  },
  {
    key: "payment-links",
    route: "/payment-links",
    name: "Payment link",
    icon: <LinkIcon />,
  },
  {
    key: "virtual-account",
    route: "/virtual-account",
    name: "Virtual account",
    icon: <VirtualAccountIcon />,
  },
  {
    key: "settlements",
    route: "/settlements",
    name: "Settlements",
    icon: <SettlementsIcon  />,
  },
];

const sendLinks = [
  { key: "wire", route: "/klasha-wire", name: "Klasha wire", icon: <WireIcon /> },
];

const accountLinks = [
  {
    key: 'account', 
    route: '/settings',
    name: 'Settings',
    icon: <SettingsIcon/>,
  },
  {
    key: 'logout',
    name: 'Logout',
    icon: <LogoutIcon/>, 
    onClick: null,
  }
]

export { mainLinks, acceptLinks, sendLinks, accountLinks };
