import React, { useState } from "react";
import { Tooltip } from "antd";
import { ButtonWrapper, InputContainer, Wrapper } from "./style";
import { ReactComponent as VisiblePassword } from "./assets/visible-password.svg";
import { ReactComponent as InvisiblePassword } from "./assets/invisible-password.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info2.svg";

interface InputProps {
    label?: string;
    labelColor?: string;
    defaultValue?: string;
    type?: "text" | "number" | "password" | "email" | 'date';
    field?: any;
    onBlur?: any
    onClick?: () => void;
    disableLabel?: boolean;
    onInput?: any
    readonly?: boolean;
    disabled?: boolean;
    onChange?: (value: object | string | number) => void;
    placeholder?: string;
    classname?: string;
    hasError?: boolean;
    height?: string;
    width?: string;
    tooltipText?: string;
    id?: string;
    value?: string;
    inputMode?:string
}

const PasswordIcon = ({
    isVisible,
    onPasswordToggle,
}: {
    isVisible: boolean;
    onPasswordToggle: () => void;
}) => {
    return (
        <ButtonWrapper type="button" onClick={onPasswordToggle}>
            {isVisible ? <VisiblePassword /> : <InvisiblePassword />}
        </ButtonWrapper>
    );
};

export const Input = ({
    label,
    type = "text",
    field,
    onBlur,
    onInput,
    onClick,
    onChange,
    disableLabel = false,
    readonly = false,
    labelColor,
    defaultValue,
    value,
    classname,
    hasError = false,
    disabled = false,
    height,
    width,
    inputMode,
    tooltipText,
    id = "",
}: InputProps) => {
    const [passWordIsVisible, setPassWordIsVisible] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);
    const [passwordTypeState, setPasswordTypeState] = useState("password");
    const onPasswordToggle = () => {
        setPassWordIsVisible(!passWordIsVisible);
        setPasswordTypeState(passWordIsVisible ? "password" : "text");
    };

    const handleInputValue = (id, type, field, value) => {
        if (id.includes("email")) {
            return field?.value?.trim()?.toLowerCase() || "";
        } else if (type === "password") {
            return field?.value?.trim() || "";
        } else {
            return field?.value || value;
        }
    }

    return (
        <Wrapper
            $labelColor={labelColor}
            $disabled={disabled}
            $height={height}
            $width={width}
            className={`${classname || ""} ${hasFocus ? "has-focus" : ""} ${hasError ? "error" : ""
                }`}
            onFocus={() => {
                setHasFocus(true);
            }}
            onBlur={() => {
                setHasFocus(false);
            }}
        >
            {!disableLabel && (
                <p
                    className={`input-label ${hasFocus ? "top" : ""} ${value?.length ? "top" : ""
                        }`}
                >
                    {label}
                </p>
            )}
            <InputContainer disabled={disabled}>
                <input
                    autoComplete="new-password"
                    id={id}
                    placeholder={disableLabel ? label : ''}
                    defaultValue={defaultValue}
                    readOnly={readonly}
                    disabled={disabled}
                    onBlur={onBlur}
                    onInput={onInput}
                    type={type === "password" ? passwordTypeState : type}
                    {...field}
                    onClick={onClick && onClick}
                    value={handleInputValue(id, type, field, value)}
                    inputMode={inputMode}
                    onChange={(e) => {
                        field?.onChange(e);
                        if (onChange) {
                            onChange(e);
                        }
                    }}
                    data-testid="input"
                    style={{  pointerEvents: disabled ? 'none' : 'auto' }}

                />
                {tooltipText && (
                    <Tooltip
                        overlayClassName="input-tooltip"
                        placement="right"
                        title={tooltipText}
                        trigger={["hover", "click"]}
                    >
                        <InfoIcon />
                    </Tooltip>
                )}
            </InputContainer>

            {type === "password" && (
                <PasswordIcon
                    isVisible={passWordIsVisible}
                    onPasswordToggle={onPasswordToggle}
                />
            )}
        </Wrapper>
    );
};
