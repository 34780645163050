import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useWindowSize } from "@reach/window-size";
import { useAppSelector } from "../../../app/hooks";
import {
    CloseButton,
    Wrapper,
    LogoWrapper,
    SectionWrapper,
    SectionWrappers,
    SectionTitle,
    SectionLinks,
    SupportLink,
    MenuBottom,
} from "./style";
import styles from "./SideMenu.module.css";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { ReactComponent as LogoK } from "../../../assets/icons/logo-k.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { acceptLinks, accountLinks, mainLinks, sendLinks } from "./links";
import { ReactComponent as SupportIcon } from "../../../assets/icons/support-icon2.svg";
import { selectUserRoleAndPermissions } from "../../../pages/Settings/Team/redux/slice";
import { useHeaderLogic } from "../../../app/hooks/useHeaderLogic";

interface Props {
    onCollapse?: any;
    isExpanded: "none" | "min" | "max";
    dropdownOpen: boolean;
    setDropdownOpen: (val: boolean) => void;
}


const SideMenu = ({
    onCollapse,
    isExpanded,
    dropdownOpen,
    setDropdownOpen,
}: Props) => {
    const { width } = useWindowSize();
    const { role, permissions } = useAppSelector(
        selectUserRoleAndPermissions,
    );

    const onMinimizeItemClick = () => {
        if (dropdownOpen) {
            setDropdownOpen(false);
        }
        if (width <= 992) {
            onCollapse();
        }
    };

    const hasPermission = (permission: string): boolean => {
        return (
            permissions &&
            permissions[permission]?.length > 0
        );
    };
    const hasAcceptPermission = permissions && acceptLinks.some(link =>
        Object.keys(permissions).includes(link.key)
    );
    
    const hasViewPermission = permissions && sendLinks.some(link =>
        Object.keys(permissions).includes(link.key)
    );

    const { logOut } = useHeaderLogic(); 
    
    return (
        <Wrapper $isExpanded={isExpanded}>
            <LogoWrapper $isExpanded={isExpanded}>
                {isExpanded === "max" ? (
                    <Logo />
                ) : (
                    <LogoK />
                )}
                <CloseButton onClick={onMinimizeItemClick}>
                    <CloseIcon />
                </CloseButton>
            </LogoWrapper>

            <SectionWrappers>
                <SectionWrapper>
                    <SectionLinks $isExpanded={isExpanded}>
                        {mainLinks.map((link) => {
                            if (
                                role === "OWNER" ||
                                role === "ADMIN" ||
                                hasPermission(link.key)
                            ) {
                                return (
                                    <NavLink
                                        to={link.route}
                                        key={link.key}
                                        aria-route={
                                            link.route
                                        }
                                        className={({
                                            isActive,
                                        }) =>
                                            isActive
                                                ? styles.isActive
                                                : styles.isLink
                                        }
                                        onClick={
                                            onMinimizeItemClick
                                        }
                                    >
                                        {link.icon}
                                        <span title="link-label">
                                            {link.name}
                                        </span>
                                    </NavLink>
                                );
                            }
                            return null;
                        })}
                    </SectionLinks>
                </SectionWrapper>
                <SectionWrapper>
                    {hasViewPermission ||
                    role === "OWNER" ? (
                        <SectionTitle
                            $isExpanded={isExpanded}
                        >
                            {isExpanded === "max"
                                ? "ACCEPT PAYMENTS"
                                : "ACCEPT"}
                        </SectionTitle>
                    ) : null}

                    <SectionLinks $isExpanded={isExpanded}>
                        {acceptLinks.map((link) => {
                            if (
                                role === "OWNER" ||
                                role === "ADMIN" ||
                                hasPermission(link.key)
                            ) {
                                return (
                                    <NavLink
                                        to={link.route}
                                        key={link.key}
                                        aria-route={
                                            link.route
                                        }
                                        className={({
                                            isActive,
                                        }) =>
                                            isActive
                                                ? styles.isActive
                                                : styles.isLink
                                        }
                                        onClick={
                                            onMinimizeItemClick
                                        }
                                    >
                                        {link.icon}
                                        <span title="link-label">
                                            {link.name}
                                        </span>
                                    </NavLink>
                                );
                            }
                            return null;
                        })}
                    </SectionLinks>
                </SectionWrapper>
                <SectionWrapper>
                    {hasAcceptPermission ||
                    role === "OWNER" ? (
                        <SectionTitle
                            $isExpanded={isExpanded}
                        >
                            {isExpanded === "max"
                                ? "MAKE PAYMENTS"
                                : "SEND"}
                        </SectionTitle>
                    ) : null}

                    <SectionLinks $isExpanded={isExpanded}>
                        {sendLinks.map((link) => {
                            if (
                                role === "OWNER" ||
                                role === "ADMIN" ||
                                hasPermission(link.key)
                            ) {
                                return (
                                    <NavLink
                                        className={({
                                            isActive,
                                        }) =>
                                            isActive
                                                ? styles.isActive
                                                : styles.isLink
                                        }
                                        to={link.route}
                                        aria-route={
                                            link.route
                                        }
                                        key={link.key}
                                        onClick={
                                            onMinimizeItemClick
                                        }
                                    >
                                        {link.icon}
                                        <span title="link-label">
                                            {link.name}
                                        </span>
                                    </NavLink>
                                );
                            }
                            return null;
                        })}
                    </SectionLinks>
                </SectionWrapper>
               
                {width < 993 && 
                    <SectionWrapper>
                        {hasAcceptPermission ||
                            role === "OWNER" ? (
                                <SectionTitle
                                    $isExpanded={isExpanded}
                                >
                                    ACCOUNT
                                </SectionTitle>
                            ) : null}
                        
                        <SectionLinks $isExpanded={isExpanded}>
                            {accountLinks.map((link) => {
                                if (
                                    role === "OWNER" ||
                                    role === "ADMIN" ||
                                    hasPermission(link.key)
                                ) {
                                    if (link.route) {
                                        return (
                                            <NavLink
                                                className={({
                                                    isActive,
                                                }) =>
                                                    isActive
                                                        ? styles.isActive
                                                        : styles.isLink
                                                }
                                                to={link.route}
                                                aria-route={
                                                    link.route
                                                }
                                                key={link.key}
                                                onClick={
                                                    onMinimizeItemClick
                                                }
                                            >
                                                {link.icon}
                                                <span title="link-label">
                                                    {link.name}
                                                </span>
                                            </NavLink>
                                        );
                                    } else if (link.onClick == null) {
                                        return (
                                            <NavLink
                                                className={styles.isLink}
                                                key={link.key}
                                                to={'/auth/login'}
                                                aria-route={
                                                    link.route
                                                }
                                                onClick={() => {
                                                    logOut()
                                                    onMinimizeItemClick();
                                                }}
                                            >
                                                {link.icon}
                                                <span title="link-label">
                                                    {link.name}
                                                </span>
                                            </NavLink>
                                        )
                                    }
                                
                                } 
                                return null;
                            })}
                        </SectionLinks>

                    </SectionWrapper>
                }
            </SectionWrappers>

            {width > 992 && 
                <MenuBottom>
                    <SupportLink $isExpanded={isExpanded}>
                        <Link to="/contact">
                            <SupportIcon />
                            <span title="hide-support-text">
                                Contact us
                            </span>
                        </Link>
                    </SupportLink>
                </MenuBottom>
            }
            
        </Wrapper>
    );
};

export default SideMenu;
